<template>
  <div class="news-wapper">
    <div class="con-con">
      <div class="news">
        <div class="title"> {{detailInfo.title}}</div>
        <div class="time">
          Posted on <span> {{ detailInfo.publishTime | format}}</span> by <span> {{detailInfo.author}}</span>

        </div>
        <div class="content" v-html="detailInfo.content">

        </div>
        <div class="post">
         Posted in <span v-if="detailInfo.tag">{{detailInfo.tag.tagName}}</span>
        </div>
        <Right/>
      </div>

    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Right from '@/views/layout/mLayRight'
  import moment from 'moment'
  export default {
    data() {
      return {
        id: this.$route.params.id,
        detailInfo: {}
      }
    },
    filters: {
      format(val) {
        return moment(val).format('MMMM DD, YYYY') // 默认为英文、引入后设置格式
      }
    },
    mounted() {

    },
    created() {
      this.getNewsDetailInfo()
    },
    computed: {},
    methods: {
      getNewsDetailInfo() {
        api.getNewsDetail(this.id).then(res => {
          if (res.status === 200) {
            this.detailInfo = res.data[0]
            console.log(this.detailInfo)
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    components: {
      Right
    }
  }
</script>

<style lang="scss">
  .con-con {

    padding: 80px 0 0 0;

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      /* Black */

      color: #000000;

      margin-bottom: 20px;
      padding: 0 16px;
    }

    .news {

      .time{
        margin: 0 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        /* Gray Blue */

        color: #5F6F8F;

        span{
          color: #2788F0;
        }
      }

      .content{
        padding: 40px 0;
        margin: 40px 16px;
        border-top: 1px solid #D7D9DD;
        border-bottom: 1px solid #D7D9DD;
        p{
              min-height: 1em;
              margin-block-start: 1em;
              margin-block-end: 1em;
            }
            h2 {
                display: block;
                font-size: 1.5em;
                margin-block-start: 0.83em;
                margin-block-end: 0.83em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-weight: bold;
            }
            font-family: Arial,sans-serifserrat,MontHelvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei;
            font-style: normal;
            font-size: 20px;
        //             font-family: Arial,sans-serifserrat,MontHelvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei;
        //     font-style: normal;
        //     font-size: 16px;
        //     line-height: 24px;
        // /* or 150% */

        // /* Black */

        // color: #1b1b1b;
        // li {
        //   list-style: disc;
        //   margin-left: 14px;
        //   margin-bottom: 0;
        //   font-size: 14px;
        // }
      }
      .post{
        margin: 0 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        /* Gray Blue */

        color: #5F6F8F;
        padding-bottom: 80px;
        span{
          color: #000000;
        }
      }

    }
  }

</style>
