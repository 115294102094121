<template>
  <div class="page-wrap">
    <div class="page-con">
      <div class="page-l">
        <div class="page-l-con">
          <div class="title">
           {{detailInfo.title}}
          </div>
          <div class="time">
            Posted on <span>{{ detailInfo.publishTime | format}}</span> by <span> {{detailInfo.author}}</span>

          </div>
         <div class="content" v-html="detailInfo.content">

         </div>
          <div class="post" v-if="detailInfo.tag">
            Posted in <span>{{detailInfo.tag.tagName}}</span>
          </div>
        </div>

      </div>
      <Right/>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Right from '@/views/layout/LayRight'
  import moment from 'moment'
  export default {
    data() {
      return {
        titleInUrl: this.$route.params.id,
        detailInfo: {}
      }
    },
    filters: {
      format(val) {
        return moment(val).format('MMMM DD, YYYY') // 默认为英文、引入后设置格式
      }
    },
    components: {
      Right
    },
    mounted() {

    },
    created() {
      this.getNewsDetailInfo()
    },
    computed: {},
    methods: {
      getNewsDetailInfo() {
        console.log('hehe', this.titleInUrl, this.$route.params)
        api.getNewsDetail(this.titleInUrl).then(res => {
          console.log(res)
          if (res.status === 200) {
            this.detailInfo = res.data[0]
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>


<style lang="scss">
  .page-l {
      width: 75%;
      .page-l-con {
        width: 85%;
      }
  }
  .page-wrap {
    background: #E9E9E9;

    .page-con {
      display: flex;
      justify-content: space-between;

      .page-l {

        background: #fff;
        padding: 100px 0;

        .page-l-con {
          margin: 0 auto;

          .title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            /* or 150% */

            /* Black */

            color: #000000;
            margin-bottom: 40px;
          }
          .time{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Gray Blue */

            color: #5F6F8F;
            span{
              color: #2788F0;
            }
          }

          .content{
            width: 100%;
            padding-top: 40px;
            margin-top: 40px;
            border-top: 1px solid #D7D9DD;
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid #D7D9DD;
            p{
              min-height: 1em;
              margin-block-start: 1em;
              margin-block-end: 1em;
            }
            h2 {
                display: block;
                font-size: 1.5em;
                margin-block-start: 0.83em;
                margin-block-end: 0.83em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-weight: bold;
            }
            font-family: Arial,sans-serifserrat,MontHelvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei;
            font-style: normal;
            font-size: 20px;
            // line-height: 32px;
            // /* or 160% */
            // text-align: justify;

            // /* Black */
            // color: #1b1b1b;
            // li {
            //     list-style: disc;
            //     margin-left: 15px;
            //     margin-bottom: 0;
            //     font-size: 17px;
            // }
            
          }
          .post{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Black */

            color: #000000;
            span{
              color: #000000;
            }
          }

        }

      }

    }
  }
</style>
